﻿
/* BLOG CONTENT */
.blog #main{
  a {
    font-weight: 100;
    text-decoration: none;
  }
  ul{
      list-style: none;
      margin: 0;
      padding: 0;
  }
}

.blog .blog-search,
.news-search {
  padding: 15px 0;
  border-bottom: 1px solid #333;
  
  @media #{$medium-up} { 
      float: right;
      width: 25%;
      clear: right;
      margin: -20px 15px 0;
  }
  &:before {
    display: block;
    padding: 0 0 8px;
    margin: 12px 0;
    font-weight: bold;
    border-bottom: 1px solid #333;
    content: 'Search the Blog';
  }
  input {
    display: inline-block;
    width: 80%;
    padding: 3px 0 3px 8px;
    outline: none;
  }
  a {
    display: inline-block;
    width: 32px;
    height: 32px;
    padding: 0;
    margin: 0 0 0 -4px;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    color: transparent;
    text-decoration: inherit;
    vertical-align: top;
    background-color: #1ebedd;

    @media #{$medium-up} {
        width: 15%;
    }

    &:before {
      display: block;
      line-height: 32px;
      text-align: center;
      font-family: FontAwesome;
      color: #fff;
      content: '\f002';
    }
  }
}

.blog .blog-categories {
  border-bottom: 1px solid #333;
  padding: 0 0 15px 0;
  margin: 0 15px 15px;
  
  @media #{$medium-up} { 
      float: right;
      width: 25%;
      clear: right;
  }
  h4 {
    margin: 12px 0 0;
  }
}

.blog .recent-posts,
.rss-container {
  width: 70%;
  h4 {
    margin: 20px 0 0;
    a {
      display: block;
      padding: 0;
      margin: 0;
      font: italic 800 24px/24px;
      color: $primary-color;
      text-decoration: none;
    }
  }
  .date-author {
    font-style: italic;
  }
  .teaser img {
    display:none;

    &:first-child{
        display: block;
        position: absolute;
        top: 2px;
        left: 0;
        width: 75px;
        height: 75px;
        border: 3px solid #aaa;
        box-shadow: 5px 5px 2px 0px rgba(0,0,0,0.1);
    }
  }
  .post {
    position: relative;
    &.rss {
      padding-left: 0px;
      img{
        display: none;
      }
    }
  }
}

/* BLOG POST */
.blog  {
  .blog-detail {
    h2 {
      margin: 20px 0 0;
    }
    .pubdate {
      display: block;
      margin-bottom: 10px;
      font-style: italic;
    }
    .copy {
      margin-bottom: 40px;
      border-bottom: 3px solid #dedede;
      img {
        margin: 10px 0;
        border: 3px solid #aaa;
        box-shadow: 5px 5px 2px 0px rgba(0,0,0,0.1);
        &.full-width {
          width: 100%;
        }
      }
    }
  }
  .blog-comments {
    h4 {
      font-size: 1.5em;
      font-weight: 100;
    }

    .form-group {
      display: inline;
      input,
      textarea{
        padding: 10px 15px;
      }
      &.name input,
      &.email input {
        width: 45%;
      }
      &.comment textarea{
        width: 92%;
        margin: 5px 0;
        height: 200px;
      }
      &.post input.btn{
        border: none;
        background-color: #999;
        padding: 10px 30px;
        color: #fff;
        position: relative;
        top: -61px;
        left: -6%;
      }
      label,
      .small {
        display: none;
      }
    }
    .comments{
        .comment-name{
            font-size: 1.5em;
            color: #1ebedd;
        }
        .comment-date{
            color: #aeaeae;
            &:before{
              content: 'on ';
            }
        }
        .comment{
            margin: 20px 0;
        }
    }
  }
}