.Events{
    
    h1{
        @include respond-to(350px){
            float: left;
        }
    }

    select{
        @include respond-to(350px){
            float: right;
            margin: -41px 0 15px 0;
        }
        width: auto;
        margin: 15px 0;
    }

    .monthNav{
        margin: 12px 0;
        @include respond-to(350px){
            float: right;
        }
        a{
            background-color: #000;
            padding: 8px;
            vertical-align: middle;
            &:first-child{
                &:before{
                    font-family: FontAwesome;
                    content: "\f060  ";
                }
            }
            &:last-child{
                &:after{
                    font-family: FontAwesome;
                    content: "  \f061";
                }
            }
        }
    }
    .monthName{
        @include respond-to(350px){
            margin-top: -45px;
        }
        @include respond-to(450px){
            text-align: center;
        }
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .calendarGrid{
        .day{
            height: 150px;
        }
        .otherMonth{
            background-color: rgba($primary-color, .25);
        }
    }

    .dayContent{
        padding: 5px 0;
        margin: 0 -10px;
        a,
        span{
            display: block;
        }
        span{
            padding: 4px;
            color: #000;
        }
    }

    ul.events-legend{
        list-style: none;
        margin: 0;
        padding: 16px;
        background-color: #f2f2f2;
        li{
            display: inline-block;
            font-weight: 600;
            margin: 16px 32px;
            .label{
                vertical-align: text-bottom;
            }
            .color{
                display: inline-block;
                width: 20px;
                height: 20px;
                margin: 0 12px 0 0;
            }
        }
    }
}