﻿.resp-tabs-container{
    h2.resp-accordion{
        font-size: 26px;

    &.resp-tab-active{
        background: #00bee0;
        a {
            color: #fff;
        }
    }
  }
}

.resp-tab-content{ 
    position: relative;
    h2{
     display: none;
    }

    p{
        font-size: 14px;
        font-style: italic;
        margin: 0 0 20px 0;
    }

    label{
        display: inline-block;
        font-weight: 600;
        margin-top: 20px;
    }
}

.calculator_fields_panel,
.calculator p{
    max-width: 65%;
    margin-left: 20px;
}

.calculator_readout_panel{
    position: absolute;
    top: 15px;
    right: 20px;
    min-height: 150px;
    width: 250px;
    background: #f2f2f2;
    text-align: center;
    border: 1px solid #dfdfdf;
 
    @include respond-to-max(768px){
        position: static;
        transform: none;
        margin-top: 20px;
        width: 100%;
    }

    label{
        margin: 5px auto 15px;
        &:nth-child(2){
            display: block;
            color:  #00bee0;
            font-weight: 600;
        }
    }
}

.calculator_field_wrap{
    margin-bottom: 40px;
}

.calculator_input_wrapper{
    float: right;
    margin: 10px 0;
    background: #f2f2f2;
    padding: 0 10px;
    color: #555;
    font-weight: 500;

    input{
        padding: 5px;
        border: none;
        color: #555;
        font-weight: 500;
        background: transparent;
        text-align: right;
    }

    &:before,
    &:after{
        display: inline-block;
        width: 10px;
        content: ' ';
    }

    &.currency{
        &:before{
            font-family: FontAwesome;
            content: '\f155';
        }
    }
    &.percent{
        &:after{
            font-weight: 700;
            content: '%';
        }
    }
}

#disclaimer,
#assumptions{
    padding: 40px 20px;
    h2{
        font-size: 1em;
        font-weight: 600;
        margin: 0;
    }
    p{
        font-size: 1em;
        font-style: italic;
    }
}