.gallery,
.album{
    margin-top: 40px;

    h2{
        font-size: 1.75rem;
        font-weight: 600;
        display: inline;
    }

    > p{
        display: inline;
        font-size: .85em;
    }

    .album-name{
        position: absolute;
        bottom: 0;
        padding: 15px;
        color: #fff;
        background-color: rgba(0, 0, 0, .35);
        width: 100%;
        p{margin: 0;}
    }
    .album-thumb{
        img{
            width: 100%;
            height: auto;
        }
    }

    ul{
        list-style: none;
        margin: 20px 0 0 0;
        li{
            position: relative;
            display: inline-block;
            vertical-align: top;
            margin: .5%;
            max-height: 250px;
            width: 100%;

            @include respond-to(320px){
                width: 48%;
            }

            @include respond-to(680px){
                width: 31%;
            }

            @include respond-to(980px){
                width: 22%;
            }
        }
    }
}