.wrap-search {
    border-bottom: 1px solid lighten($gray, 20%);
    background: lighten($gray, 12%);
    .wrap-search-inner {
        position: relative;
        background: lighten($gray, 19%);
        input[type="text"] {
            margin: 0;
            @include px-rem(padding, 0, 1.375);
            @include px-rem(height, 2.188);
            border: none;
            background: transparent;
            @include font-size(0.8125, 2.188);
            font-style: italic;
            font-weight: 500;
            color: lighten($gray, 51%);
            width: 94%;
            &:focus {
                outline: none;
                border: none;
                box-shadow: none;
            }
        }
        button {
            position: absolute;
            @include px-rem(top, 0.5);
            @include px-rem(right, 1.375);
            padding: 0;
            margin: 0;
            border: none;
            outline: none;
            background: transparent;
            color: lighten($gray, 51%);
        }
    }
}
