.wrap-slider {
    margin-bottom: 2.6rem;
    background-size: auto auto;
}

.bg-slider {
    background: white;
}

.slide {
    display: none;
    position: relative;
    width: 100%;
    img {
        display: block;
    }
    .details {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0.25rem 0.5rem;
        width: 100%;
        background: rgba(black, 0.4);
        .heading,
        .teaser {
            display: block;
            text-align: left;
        }
        .heading {
            @include font-size(1.125, 1.125);
            font-weight: 600;
            color: white;
        }
        .teaser {
            display: none;
            @include font-size(0.8125, 0.8125);
            text-transform: uppercase;
            color: $dark-blue;
            span {
                font-weight: 700;
            }
        }
        @media #{$medium-up} {
            position: absolute;
            top: 40%;
            padding: 1rem 0.875rem 1rem 4.563rem;
            height: 4.563rem;
            width: auto;
            background: $blue;
            &:after {
                content: '';
                line-height: 0;
                font-size: 0;
                width: 0;
                height: 0;
                border-top: 4.625rem solid $blue;
                border-bottom: 2.313rem solid transparent;
                border-left: 0px solid transparent;
                border-right: 4.5rem solid transparent;
                position: absolute;
                top: 0;
                right: -4.5rem;
            }
            .heading {
                margin-bottom: 0.45rem;
            }
            .teaser {
                display: block;
            }
        }
    }
}

.cycle-pager {
    margin-top: -3px;
    span {
        cursor: pointer;
        display: inline-block;
        margin-top: -2px;
        width: (100% / 3);
        height: 0.625rem;
        background: $light-blue;
        border-right: 1px solid white;
        &:last-child {
            border-right: none;
        }
        &.cycle-pager-active {
            background: $blue;
        }
    }
}
