// $iebar-bg: #ffc410;
// $iebar-border: #eab105;
// $iebar-txt-top: #755603;
// $iebar-txt-bottom: #4c3906;
// $iebar-txt-bottom-hover: #222;

// .ie-bar {
//   width: 100%;
//   padding: 1rem 0.9375rem;
//   font-family: Tahoma, Verdana, Segoe, sans-serif;
//   text-align: center;
//   background: $iebar-bg;
//   border-bottom: 5px solid $iebar-border;
//   box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
//   p {
//     padding: 0;
//     margin: 0;
//     font-weight: 700;
//     letter-spacing: 1px;
//     color: $iebar-txt-top;
//     text-transform: uppercase;

//     @include font-size(0.75, 1.125);
//     a {
//       display: block;
//       color: $iebar-txt-bottom;
//       &:hover {
//         color: $iebar-txt-bottom-hover;
//       }
//     }
//   }
// }

.ieBar {
  @include px-rem(padding, 1, 3);
  width: 100%;
  background: #3b3b3b url("/assets/css/img/iebar-icons.gif") no-repeat center center;
  text-align: center;
  p {
    margin: 0;
    padding: 0;
    color: white;
    @include font-size(0.9);
    font-family: Consolas, monaco, monospace;
    text-align: center;
  }
  a {
    color: #dd5a5a;
    text-decoration: underline;
    &:hover {
      color: #c74b4b;
    }
  }
}
