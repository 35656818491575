.logo-header {
    @include px-rem(padding-top, 1);
    @include px-rem(padding-bottom, 1);
    img {
        display: block;
        margin: 0 auto;
    }
    @media #{$medium-up} {
        img {
            display: inline-block;
        }
    }
}
