﻿.alert{
    display: block;
    padding: 12px;
    color: #fff;
    background-color: $primary-color;
    height: 60px;

    .fa-close{
        height: 35px;
        width: 5%;
        text-align: right;
        line-height: 35px;
        vertical-align: middle;
        &:hover{
            cursor: pointer;
            color: #016e82;
        }
    }

    &.closed{
        height: 0;
        padding: 0;
        transition: all .3s ease-out;
    }
}

.alert-container{
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
    padding: 0 10px;
    position: relative;
    display: block;
}

.alert-details{
    width: 90%;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
}

.alert-heading{
    font-weight: 700;
}

.alert-content{
    margin-left: 8px;
}