.widget {
    margin-bottom: 1.25rem;
    &:last-child {
        margin-bottom: 0;
    }
    @media #{$medium-up} {
        margin-bottom: 0;
    }
    h3 {
        padding: 1.2rem 1.25rem;
        @include font-size(1.25, 1.25);
        font-weight: 300;
        text-transform: uppercase;
        color: white;
        background: $blue;
    }
    ul {
        li {
            padding: 1.2rem 1.25rem;
            border-bottom: 1px solid $dull-blue;
            a {
                font-weight: bold;
            }
            &:nth-child(even) {
                background: $white-gray;
            }
            &:last-child {
                border-bottom: none;
            }
            &.archive {
                @include font-size(0.8125, 0.8125);
                text-transform: uppercase;
                background: $archive-gray;
                a {
                    color: $medium-gray;
                    &:hover {
                        color: $gray;
                    }
                }
            }
            h4 {
                margin: 0;
                @include font-size(0.9375, 1.2);
            }
            .date {
                @include font-size(0.75, 0.75);
                font-weight: 700;
                color: $medium-gray;
            }
        }
    }
    &.widget-newsletter-signup {
        h3 {
            padding: 0 0 0 2.25rem;
            @include font-size(1.375, 1.5);
            text-transform: none;
            background: transparent;
            color: $blue;
            i {
                position: absolute;
                left: 0.9375rem;
                @include font-size(1.75, 1.75);
                color: $dull-blue;
            }
        }
    }
}
