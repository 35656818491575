fieldset{
    border: 0;

    h2{
        font-size: 18px;
        font-weight: 800;
        border-bottom: 1px solid #dedede;
    }
    h3{
        font-size: 13px;
        line-height: 1rem;
    }

    label{
        display: block;
    }

    input,
    textarea{
        width: 100%;
    }

    select{
        height: 28px;
    }

    input[type=checkbox]{
        width: auto;

        + label{
            display: inline;
        }
    }
}

input[type=submit]{
    float: right;
    margin-right: 27px;
}

.btn{
    border: 0;
    padding: 5px 15px;
    &.btn-primary{
        background-color: #000;
        color: $blue;
        &:hover{
            color: $dark-blue;
        }
    }
}

.full,
.half,
.third,
.two-third,
.quarter {
  float: left;
  padding-right: rem-calc(15px);
  padding-left: rem-calc(15px);
}
.full {
  width: 100%;
}
.half {
  width: 100%;
  @media #{$medium-up} {
    width: 50%;
  }
}
.third {
  width: 100%;
  @media #{$medium-up} {
    width: 33.333%;
  }
}
.two-third {
  width: 100%;
  @media #{$medium-up} {
    width: 66.666%;
  }
}
.quarter {
  width: 100%;
  @media #{$medium-up} {
    width: 25%;
  }
}
.full,
.half,
.third,
.two-third,
.quarter {
  &.last:after { 
     visibility: hidden;
     display: block;
     font-size: 0;
     content: " ";
     clear: both;
     height: 0;
  }
}
.captcha {
  width: 100%;
  padding-right: rem-calc(15px);
  padding-left: rem-calc(15px);
  img {
    margin: rem-calc(0 0 5px 0);
  }
  div {
    font-weight: 700;
    color: darken($text, 7%);
    text-align: left;

    @include font-size(15, 17);
    &:not([class*='Normal']) {
      margin-bottom: rem-calc(5px);
    }
  }
}
