$gray: #141414;
$medium-gray: #a6a6a6;
$white-gray: #fafafa;
$archive-gray: #f6f6f6;

$blue: rgb(0, 190, 224);
$dark-blue: #016e82;
$light-blue: #bfd2d6;
$dull-blue: #dfe8ea;
$text: #656464;

$nav: #8c9597;
$nav-blue: #11d3f6;

// Fonts
$proxima: "Proxima Nova", "Open Sans", "Gill Sans MT", "Gill Sans", Corbel, Arial, sans-serif;
