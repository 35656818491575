

.locations{
    margin-top: 16px;
    
    select{
        float: right;
        width: auto;
        margin: -41px 0 15px 0;
        border: none;
    }

    h2.underline{
        font-size: 18px;
        color: $blue;
        border-bottom: 2px solid $blue;
        font-weight: 600;
    }

    ul{
        list-style: none;
        li{
            display: inline-block;
            width: 22%;
            vertical-align: top;
            margin: 0 .5%;
            border: 1px solid #dedede;
            &:first-child{
                margin-left: 0;
            }

            &:last-child{
                margin-right: 0;
            }

            img{
                width: 100%;
                height: auto;
            }

            h3{
                margin-top: -35px;
                margin-bottom: 20px;
                padding: 0 10px;
                background-color: rgba($blue, .25);
                font-size: 1rem;
                position: relative;
                color: #fff;
            }

            p,
            .btn{
                margin: 15px 10px;
            }

            .btn{
                float: right;
            }
        }
    }
}