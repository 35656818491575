h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 0.75rem 0;
    font-family: $proxima;
    color: $text;
}

h1,
h2,
h3,
h4 {
    font-weight: 400;
}

h1 {
    @include font-size(2.5, 2.5);
}

h2 {
    @include font-size(2.25, 2.25);
    .home & {
        @include font-size(2.5, 2.5);
    }
}

h3 {
    @include font-size(2, 2);
}

h4 {
    @include font-size(1.8, 1.8);
}

h5 {
    @include font-size(1.8, 1.8);
    font-weight: 700;
}

p,
ul,
ol,
dl,
blockquote {
    @include font-size(1.25rem);
}
