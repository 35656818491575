/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/


// Presets & Variables
@import 'variables';
@import 'mixins';

// Foundation
@import "foundation/foundation/_settings";
@import "foundation/normalize";
@import "foundation/foundation";

// Layout
@import "layout/header";
@import "layout/header-search";
@import "layout/slider";
@import "layout/sidebar";
@import "layout/widgets";
@import "layout/footer";

// Components
@import "components/general";
@import "components/branding";
@import "components/nav-primary";
@import "components/typography";
@import "components/alert";
@import "components/locations";
@import "components/gallery";
@import "components/events";
@import "components/blog";
@import "components/content";
@import "components/forms";
@import "components/calculators";
@import "components/iebar";

// Vendor
@import 'vendor/font-awesome/4.2.0/scss/font-awesome';
@import 'vendor/Magnific/css/main';
@import 'vendor/tabs2accordion/easy-responsive-tabs';

// Extras
@import 'shame';
