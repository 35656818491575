footer {
    padding: 2rem 0 8rem 0;
    p {
        margin: 0;
        @include font-size(0.8125, 0.8125);
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
        color: white;
    }
}
