.nav-primary {
    ul {
        margin: 0 auto 1rem auto;
        list-style: none;
        text-align: center;
        text-transform: uppercase;
        @include font-size(0.8125, 0.8125);
        font-weight: 600;
        color: $nav;
        @media #{$medium-up} {
            margin: 2.375rem 0 0 0;
            text-align: right;
        }
        li {
            display: inline;
            @include px-rem(margin-right, 0.4);
            &:after {
                position: relative;
                @include px-rem(top, -0.1);
                @include px-rem(right, -0.2);
                content: "|";
                @include font-size(0.75, 0.7);
            }
            &:last-child {
                margin: 0;
                &:after {
                    content: "";
                }
            }
            a {
                color: $nav-blue;
                &:hover {
                    color: $blue;
                }
            }
        }
    }
}
