html,
body {
    background: $blue;
    font-family: $proxima;
    color: $text;
}

.row {
    max-width: 61.25rem;
}

.table-wrap {
    display: table;
    margin: 0 auto;
}

.element-invisible {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}

.clear {
    display: block;
    clear: both;
}

.wrap-body {
    background: white;
    padding-bottom: 3rem;
}

a {
    color: $blue;
    &:hover {
        color: $dark-blue;
    }
}
